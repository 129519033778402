<template>
    <div class="container-fluid">
        <b-row>
            <b-col lg="12">
                <card class=" card-block card-stretch card-height">
                    <template v-slot:headerTitle>
                        <h4 class="card-title mb-0">Estadísticas {{ intelinsuranceApi.user.company }} - Benchmarking
                        </h4>

                    </template>
                    <!--template v-slot:headerAction>
                        <router-link :to="{ name: 'contact.add'}" class="btn btn-primary">
                           Agregar
                        </router-link>
                        <router-link :to="{ name: 'contact.import'}" class="btn btn-primary ml-2">
                           Importar
                        </router-link>
                   </template-->
                    <template v-slot:body>
                        <!--div class="form-group">
                        <label for="model">Listado</label>
                        <select class="form-control mb-3"
                        id="configuration_list"
                        name="configuration_list"
                        @change="infoautoGetVersions($event)"
                         >
                            <option value="-">Seleccione</option>
                            <option v-for="configuration in configuration_list" :value="configuration.id" :key="configuration.id">
                            {{ configuration.name }}({{configuration.id}})
                            </option>
                        </select>
                    </div-->
                        <b-row>
                            <b-col lg="4">
                                <div class="form-group ">
                                    <label for="model">Fecha</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'fecha')">
                                        <option value="may">Mayo</option>
                                        <option value="june">Junio</option>
                                        <option value="july">Julio</option>
                                        <option value="september">Septiembre</option>
                                        <option value="october">Octubre</option>
                                        <option value="november">Noviembre</option>
                                        <option value="dicember">Diciembre</option>
                                        <option value="january-23">Enero '23</option>
                                        <option value="february-23">Febrero '23</option>
                                        <option value="march-23">Marzo '23</option>
                                        <option value="april-23">Abril '23</option>
                                        <option value="may-23">Mayo '23</option>
                                        <option value="june-23">Junio '23</option>
                                        <option value="july-23">Julio '23</option>
                                        <option value="august-23">Agosto '23</option>
                                        <option value="september-23">Septiembre '23</option>
                                        <option value="october-23">Octubre '23</option>
                                        <option value="november-23">Noviembre '23</option>
                                        <option value="dicember-23">Diciembre '23</option>
                                        <option value="january-24">Enero '24</option>
                                        <option value="february-24">Febrero '24</option>
                                        <option value="march-24">Marzo '24</option>
                                        <option value="april-24">Abril '24</option>
                                        <option value="may-24">Mayo '24</option>
                                        <option value="june-24">Junio '24</option>
                                        <option value="july-24">Julio '24</option>
                                        <option value="august-24">Agosto '24</option>
                                        <!--option value="august">Agosto</option-->
                                    </select>
                                </div>
                            </b-col>

                            <b-col lg="4">
                                <div class="form-group ">
                                    <label for="model">Pago</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'payment')">
                                        <option value="-">Seleccione</option>
                                        <option value="1">Efectivo</option>
                                        <option value="2">Tarjeta</option>
                                    </select>
                                </div>
                            </b-col>
                            <b-col lg="4">
                                <!--div class="form-group">
                                <label for="model">Compañias</label>
                                <select class="form-control mb-3"
                                id="configuration_list"
                                name="configuration_list"
                                @change="setResultsFilter($event, 'company')"
                                >
                                    <option value="-">Seleccione</option>
                                    <option v-for="car in configuration_cars" :value="car.id" :key="car.id">
                                    {{ car.brand }} {{ car.model }} {{ car.year }}
                                    </option>
                                </select>
                            </div-->
                                <div class="form-group">
                                    <label for="model">Cobertura</label>
                                    <select class="form-control mb-3" id="configuration_list" name="configuration_list"
                                        @change="setResultsFilter($event, 'cobertura')">

                                        <option value="-">Seleccione</option>
                                        <option value="A" v-if="intelinsuranceApi.user.company != 'Experta'">
                                            Responsabilidad Civil</option>
                                        <option value="B" v-if="intelinsuranceApi.user.company != 'Experta'">Terceros
                                            Totales</option>
                                        <option value="C Base" v-if="intelinsuranceApi.user.company != 'Experta'">
                                            Terceros Completos Base</option>
                                        <option value="C Full">Terceros Completos Full</option>
                                        <option value="D1">Todo Riesgo F. Alta</option>
                                        <option value="D" v-if="intelinsuranceApi.user.company != 'Experta'">Todo Riesgo
                                            F. Baja</option>
                                    </select>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>

                            <b-col lg="5">
                                <div class="form-group ">
                                    <label for="model">Localidades</label>
                                    <treeselect class="mb-3" v-model="multipleSelectedPlaces"
                                        @input="setResultsFilter($event, 'place')" :multiple="true"
                                        :options="configuration_places" />
                                    <!-- <select class="form-control mb-3"
                                id="configuration_list"
                                name="configuration_list"
                                @change="setResultsFilter($event, 'place')" multiple v-model="multipleSelectedPlaces"
                                >
                                    <option value="-">Seleccione</option>
                                    <option v-for="place in configuration_places" :value="place.id" :key="place.id">
                                    {{ place.city }}
                                    </option>
                                </select> -->
                                </div>
                            </b-col>
                            <b-col lg="5">
                                <div class="form-group">
                                    <label for="model">Autos</label>
                                    <treeselect class="mb-3" v-model="multipleSelected"
                                        @input="setResultsFilter($event, 'car')" :multiple="true"
                                        :options="configuration_cars" />
                                </div>
                            </b-col>

                            <!-- <b-col lg="3">
                            <div class="form-group">
                                <label for="model">Autos</label>
                                <select class="form-control mb-3"
                                id="configuration_list"
                                name="configuration_list"
                                @change="setResultsFilter($event, 'car')" multiple  v-model="multipleSelected"
                                >
                                    <option value="-">Seleccione</option>
                                    <option value="all" v-if="filterPlace !='all'">TODOS</option>
                                    <option v-for="car in configuration_cars" :value="car.id" :key="car.id">
                                    {{ car.brand }} {{ car.model }} {{ car.year }}
                                    </option>
                                </select>
                            </div>
                        </b-col> -->

                            <b-col lg="2">
                                <div class="form-group">
                                    <label for="model" style="min-height:24px; width:100%;"></label>
                                    <button type="submit" class="btn btn-primary"
                                        @click="getResultsFilter()">Filtrar</button>
                                </div>
                            </b-col>
                        </b-row>

                        <span v-if="isLoading">Cargando...</span>

                        <b-row v-if="getResultados">
                            <b-col lg="12" v-for="(item, index) in chart" :key="componentKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <AmCharts :element="item.type" :type="item.type" :option="item.bodyData"
                                            height="500" />
                                    </template>
                                </card>
                            </b-col>
                            <b-col lg="6" v-for="(item, index) in charts" :key="graficoTortaAutosKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <AmCharts :element="item.type" :type="item.type" :option="item.bodyData"
                                            height="500" />
                                    </template>
                                </card>
                            </b-col>
                            <b-col lg="6" v-for="(item, index) in chartVehiculoPropio"
                                :key="graficoTortaAutosPropioKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <AmCharts :element="item.type" :type="item.type" :option="item.bodyData"
                                            height="500" />
                                    </template>
                                </card>
                            </b-col>
                            <!-- <b-col lg="12" v-for="(item,index) in chartLocation" :key="componentKeyLocation">
                            <card>
                            <template v-slot:headerTitle>
                                <h4>{{ item.title }}</h4>
                            </template>
                            <template v-slot:body>
                                <AmCharts :element="item.type" :type="item.type" :option="item.bodyData" height="500"  />
                            </template>
                            </card>
                        </b-col> -->
                            <b-col lg="6" v-for="(item, index) in chartTortaLocation" :key="graficoTortaLocationKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <AmCharts :element="item.type" :type="item.type" :option="item.bodyData"
                                            height="500" />
                                    </template>
                                </card>
                            </b-col>
                            <b-col lg="6" v-for="(item, index) in chartLocationPropio"
                                :key="graficoTortaLocationPropioKey">
                                <card>
                                    <template v-slot:headerTitle>
                                        <h4>{{ item.title }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <p style="font-size: 12px;">{{ item.desc }}</p>
                                        <AmCharts :element="item.type" :type="item.type" :option="item.bodyData"
                                            height="500" />
                                    </template>
                                </card>
                            </b-col>
                        </b-row>
                    </template>
                </card>
            </b-col>
            <!--b-col sm="2" lg="2" class="wookie-col-2">
               <img src="@/assets/images/logo-wookie-alt-white.png" alt="">
            </b-col-->
        </b-row>
    </div>
</template>
<script>
import { core } from '../../../config/pluginInit'
import { mapGetters, mapState } from 'vuex'
import AmCharts from "../../../components/charts/AmChart";
import store from "@/store/index";
import * as am4core from '@amcharts/amcharts4/core'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    components: {
        AmCharts,
        Treeselect

    },
    data() {
        return {
            contactList: [''],
            configuration_list: [],
            configuration_places: [],
            configuration_cars: [],
            configuration_result: [],
            selectedItems: [],
            dataTable: '',
            deleteWarning: 'Eliminando elementos...',
            doneDelete: 0,
            isSelectAll: false,
            filterCompany: '',
            filterPlace: '',
            filterCar: '',
            filterQuoteGroup: '',
            filterBenchDate: '',
            filterPayment: '',
            dataFilter: {},
            componentKey: 10,
            graficoTortaAutosKey: 20,
            graficoTortaAutosPropioKey: 30,
            componentKeyLocation: 15,
            graficoTortaLocationKey: 25,
            graficoTortaLocationPropioKey: 35,
            getResultados: false,
            isLoading: false,
            selectedCar: [],
            multipleSelected: [],
            multipleSelectedPlaces: [],
            chart: [{
                title: "Escenario general",
                desc: "El presente gráfico, muestra la relación que se establece entre los costos totales de cada aseguradora, para la muestra consultada, con el total de localidades y de vehículos seleccionados",
                type: "column",
                componentKey: 10,
                id: "Zoomable on Value Axis",
                valueYShow: "valueYTotalPercent",
                min: 0,
                bodyData: {
                    color: "#0084ff",
                    fill: "#fdd400",
                    data: [

                    ],
                },
            }],
            charts: [
                {
                    title: "Participación por Vehículo Mercado",
                    desc: "Los gráficos explican, cómo queda distribuido para cada vehículo, el total de localidades seleccionadas, y de la misma manera, como distribuye el mercado ese mismo total. Sirve para que la aseguradora cliente, pueda medir su correlación respecto del mercado, para los vehículos de la selección realizada.",
                    type: "pie",
                    bodyData: {
                        colors: [
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                        ],
                        value: ["premio"],
                        category: ["cars"],
                        data: [
                        ],
                    },
                }
            ],
            chartVehiculoPropio: [
                {
                    title: "Participación por Vehículo Cliente",
                    desc: "Los gráficos explican, cómo queda distribuido para cada vehículo, el total de localidades seleccionadas, y de la misma manera, como distribuye el mercado ese mismo total. Sirve para que la aseguradora cliente, pueda medir su correlación respecto del mercado, para los vehículos de la selección realizada.",
                    type: "pie_2",
                    bodyData: {
                        colors: [
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                        ],
                        value: ["premio"],
                        category: ["cars"],
                        data: [
                        ],
                    },
                }
            ]
            , chartLocation: [{
                title: "Escenario general por localidad",
                desc: "Los gráficos explican, cómo queda distribuido para cada vehículo, el total de localidades seleccionadas, y de la misma manera, como distribuye el mercado ese mismo total. Sirve para que la aseguradora cliente, pueda medir su correlación respecto del mercado, para los vehículos de la selección realizada.",
                type: "column_2",
                componentKey: 20,
                id: "Zoomable on Value Axis",
                bodyData: {
                    color: "#0084ff",
                    fill: "#fdd400",
                    data: [

                    ],
                },
            }],
            chartTortaLocation: [
                {
                    title: "Participación por Localidad Mercado",
                    desc: "Los gráficos explican, cómo queda distribuido para cada localidad, el total de vehículos seleccionados, y de la misma manera, como distribuye el mercado ese mismo total. Sirve para que la aseguradora cliente, pueda medir su correlación respecto del mercado, para las localidades de la selección realizada.",
                    type: "pie_3",
                    bodyData: {
                        colors: [
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                        ],
                        value: ["premio"],
                        category: ["locations"],
                        data: [
                        ],
                    },
                }
            ],
            chartLocationPropio: [
                {
                    title: "Participación por Localidad Cliente",
                    desc: "Los gráficos explican, cómo queda distribuido para cada localidad, el total de vehículos seleccionados, y de la misma manera, como distribuye el mercado ese mismo total. Sirve para que la aseguradora cliente, pueda medir su correlación respecto del mercado, para las localidades de la selección realizada.",
                    type: "pie_4",
                    bodyData: {
                        colors: [
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                            "#0084ff",
                            "#00ca00",
                            "#e64141",
                            "#ffd400",
                            "#00d0ff",
                            "#374948",
                        ],
                        value: ["premio"],
                        category: ["locations"],
                        data: [
                        ],
                    },
                }
            ]
        }
    },
    name: 'StatisticsFilter',
    computed: {
        ...mapState(["productores", "infoauto", "procloud", "intelinsuranceApi"])
    },
    mounted() {


        console.log(this.intelinsuranceApi.user.configuration_id);
        console.log(this.intelinsuranceApi.user.company);

        store
            .dispatch("procloud/fetchConfigurationList")
            .then(() => {
                this.configuration_list = this.procloud.benchkmarking_configuration_list
            });



        store
            .dispatch("procloud/fetchConfigurationPlaces", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                console.log(this.procloud.benchkmarking_configuration_places)
                this.configuration_places = this.procloud.benchkmarking_configuration_places
            });

        // console.log(this.configuration_places)

        store
            .dispatch("procloud/fetchConfigurationData", this.intelinsuranceApi.user.configuration_id)
            .then(() => {
                console.log(this.procloud.benchkmarking_configuration_data.places)
                this.configuration_places = this.procloud.benchkmarking_configuration_data.places
                this.configuration_cars = this.procloud.benchkmarking_configuration_data.cars
                this.configuration_companies = this.procloud.benchkmarking_configuration_data.companies
                //console.log(this.procloud.benchkmarking_configuration_data);
                //this.configuration_data = this.procloud.benchkmarking_configuration_data

                console.log(this.configuration_places)
                console.log(this.configuration_cars)
            });
        //this.list()

    },
    beforeMount() {
        $('body').attr('id', "list-result");
    },
    destroyed() {
        //core.initDataTable()
    },
    methods: {
        setResultsFilter: function (event, type) {

            this.dataFilter = {};



            if (type == 'companies') {
                this.filterCompany = event.target.value;
            }
            if (type == 'place') {
                //this.filterPlace = event.target.value;
                console.log(event.target);
                console.log(this.multipleSelected);
                this.filterPlace = this.multipleSelectedPlaces;
            }
            if (type == 'car') {
                console.log(event.target);
                console.log(this.multipleSelected);
                this.filterCar = this.multipleSelected;
            }
            if (type == 'cobertura') {
                this.filterQuoteGroup = event.target.value;
            }
            if (type == 'fecha') {
                this.filterBenchDate = event.target.value;
            }
            if (type == 'payment') {
                this.filterPayment = event.target.value;
            }

            if (this.filterCompany != '') this.dataFilter.company = this.filterCompany
            if (this.filterPlace != '') this.dataFilter.location = this.filterPlace
            if (this.filterCar != '') this.dataFilter.car = this.filterCar
            if (this.filterQuoteGroup != '') this.dataFilter.grupo = this.filterQuoteGroup
            if (this.filterBenchDate != '') this.dataFilter.fecha = this.filterBenchDate
            if (this.filterPayment != '') this.dataFilter.payment = this.filterPayment

            //this.dataFilter.payment = 123
            this.dataFilter.configurationId = this.intelinsuranceApi.user.configuration_id
            this.dataFilter.companyReference = this.intelinsuranceApi.user.company

            console.log(this.dataFilter);

        },
        getResultsFilter: function (event, type) {

            this.configuration_result = '';

            this.isLoading = true;
            this.getResultados = false;
            store
                .dispatch("procloud/fetchConfigurationStatistics", this.dataFilter)
                .then(() => {
                    this.configuration_result = this.procloud.benchkmarking_configuration_statistics;

                    // if (this.filterCar == 'all' || this.filterCar.length > 0) {
                    //     this.chart[0].title = 'Escenario general por localidad'
                    //     this.charts[0].title = 'Participación por vehículo Mercado'
                    //     this.chartVehiculoPropio[0].title = 'Participación por vehículo Propio'
                    // }

                    // if (this.filterPlace == 'all' || this.filterPlace.length > 0) {
                    //     this.chart[0].title = 'Escenario general por localidad'
                    //     this.charts[0].title = 'Participación por vehículo Mercado'
                    //     this.chartVehiculoPropio[0].title = 'Participación por vehículo Propio'
                    // }

                    this.chart[0].bodyData.data = [];
                    this.charts[0].bodyData.data = [];
                    this.chartVehiculoPropio[0].bodyData.data = [];
                    for (let index = 0; index < this.configuration_result.cars.length; index++) {
                        const element = this.configuration_result.cars[index];
                        var obj = { cars: element.brand + ' ' + element.version + ' ' + element.year, premio: element.total_quotes_by_car };
                        this.charts[0].bodyData.data.push(obj);
                    }
                    for (let index = 0; index < this.configuration_result.cars.length; index++) {
                        const element = this.configuration_result.cars[index];
                        var obj = { cars: element.brand + ' ' + element.version + ' ' + element.year, premio: element.total_quotes_company_by_car };
                        this.chartVehiculoPropio[0].bodyData.data.push(obj);
                    }
                    var obj = { company: "", value: 0, width: 2 };
                    this.chart[0].bodyData.data.push(obj);

                    let sortedcompanies = this.configuration_result.companies.sort((p1, p2) => (p1.quote_total_by_car < p2.quote_total_by_car) ? -1 : (p1.quote_total_by_car > p2.quote_total_by_car) ? 1 : 0);

                    for (let index = 0; index < sortedcompanies.length; index++) {
                        const element = sortedcompanies[index];
                        if (element.quote_total_by_car > 0) {
                            var valorAuto = element.quote_total_by_car;
                            // if (element.has_price == false) {
                            //     valorAuto = element.quote_total_by_car;
                            //     //valorAuto = element.quote_total * this.configuration_result.locations.length;
                            // }
                            var obj = { company: element.company_name, value: valorAuto };
                            if (element.indice_premio > 1) {
                                var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
                            } else if (element.indice_premio < 1) {
                                var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
                                //var obj = { company: element.company_name, value: valorAuto, cheaper: true, color: am4core.color('#ffbdbd') };
                            } else if (element.indice_premio == 1) {
                                var obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
                                //var obj = { company: element.company_name, value: valorAuto, equal: true, color: am4core.color('#acdcff') };
                            }
                            if (element.has_price == false) {
                                // var valorAuto = (element.quote_total_location * (this.configuration_result.cars.length));
                                obj = { company: element.company_name, value: valorAuto, cheaper: true, color: am4core.color('#ffbdbd'), fillOpacity: 0.2 };
                            } else {
                                obj = { company: element.company_name, value: valorAuto, grather: true, color: am4core.color('#bdffcd') };
                            }
                            if (element.company_name == this.dataFilter.companyReference) {

                                obj = {
                                    company: element.company_name,
                                    value: valorAuto,
                                    strokeWidth: 1,
                                    columnDash: "5,5",
                                    fillOpacity: 0.2,
                                    "color": am4core.color("#acdcff")
                                };
                            }
                            this.chart[0].bodyData.data.push(obj);
                        }

                        // ESTA PARTE ES PARA BARRA SEGUN INDICE
                        // if (element.quote_media > 0) {
                        //     var obj = { company: element.company_name, value: element.indice_premio };
                        //     if (element.indice_premio > 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio, grather: true, color: am4core.color('#bdffcd') };
                        //     }else if (element.indice_premio < 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio, cheaper: true, color: am4core.color('#ffbdbd') };
                        //     }else if (element.indice_premio == 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio, equal: true, color: am4core.color('#acdcff') };
                        //     }
                        //     if (element.company_name == this.dataFilter.companyReference) {
                        //         obj = {
                        //             company: element.company_name,
                        //             value: element.indice_premio,
                        //             strokeWidth: 1,
                        //             columnDash: "5,5",
                        //             fillOpacity: 0.2 ,
                        //             "color": am4core.color("#acdcff")
                        //         };
                        //     }
                        //     this.chart[0].bodyData.data.push(obj);
                        // }
                        //this.chart.bodyData.data.push();
                    }

                    this.componentKey = this.componentKey + 1;
                    this.graficoTortaAutosKey = this.graficoTortaAutosKey + 1;
                    this.graficoTortaAutosPropioKey = this.graficoTortaAutosPropioKey + 1;

                    this.chartLocation[0].bodyData.data = [];
                    this.chartTortaLocation[0].bodyData.data = [];
                    this.chartLocationPropio[0].bodyData.data = [];
                    for (let index = 0; index < this.configuration_result.locations.length; index++) {
                        const element = this.configuration_result.locations[index];
                        var obj = { locations: element.city + ' ' + element.state, premio: element.total_by_car };
                        this.chartTortaLocation[0].bodyData.data.push(obj);
                    }
                    for (let index = 0; index < this.configuration_result.locations.length; index++) {
                        const element = this.configuration_result.locations[index];
                        var obj = { locations: element.city + ' ' + element.state, premio: element.total_quotes_company_by_location };
                        this.chartLocationPropio[0].bodyData.data.push(obj);
                    }
                    for (let index = 0; index < this.configuration_result.companies.length; index++) {
                        // const element = this.configuration_result.companies[index];
                        // if (element.quote_total_by_car > 0) {
                        //     // var obj = { company: element.company_name, value: element.quote_total_location };
                        //     console.log("ACA ESTAMOS")
                        //     if (element.has_price == false) {
                        //         obj = { company: element.company_name, value: element.quote_total_by_car, cheaper: true, color: am4core.color('#ffbdbd'), fillOpacity: 0.2 };
                        //     } else {
                        //         obj = { company: element.company_name, value: element.quote_total_by_car, grather: true, color: am4core.color('#bdffcd') };
                        //     }
                        //     if (element.has_price == undefined) {
                        //         obj = { company: element.company_name, value: element.quote_total_by_car, grather: true, color: am4core.color('#bdffcd') };
                        //     }
                        //     if (element.company_name == this.dataFilter.companyReference) {
                        //         var valorAuto = element.quote_total_by_car;
                        //         obj = {
                        //             company: element.company_name,
                        //             value: element.quote_total_location,
                        //             strokeWidth: 1,
                        //             columnDash: "5,5",
                        //             fillOpacity: 0.2,
                        //             "color": am4core.color("#acdcff")
                        //         };
                        //     }
                        //     //this.chartLocation[0].bodyData.data.push(obj);
                        // }
                        // ESTA PARTE ES PARA BARRA SEGUN INDICE
                        // if (element.quote_media_location > 0) {
                        //     var obj = { company: element.company_name, value: element.indice_premio_localidad };
                        //     if (element.indice_premio_localidad > 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio_localidad, grather: true, color: am4core.color('#bdffcd') };
                        //     }else if (element.indice_premio_localidad < 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio_localidad, cheaper: true, color: am4core.color('#ffbdbd') };
                        //     }else if (element.indice_premio_localidad == 1) {
                        //         var obj = { company: element.company_name, value: element.indice_premio_localidad, equal: true, color: am4core.color('#acdcff') };
                        //     }
                        //     if (element.company_name == this.dataFilter.companyReference) {
                        //         obj = {
                        //             company: element.company_name,
                        //             value: element.indice_premio_localidad,
                        //             strokeWidth: 1,
                        //             columnDash: "5,5",
                        //             fillOpacity: 0.2 ,
                        //             "color": am4core.color("#acdcff")
                        //         };
                        //     }
                        //     this.chartLocation[0].bodyData.data.push(obj);
                        // }
                        //this.chart.bodyData.data.push();
                    }

                    this.componentKeyLocation = this.componentKeyLocation + 1;
                    this.graficoTortaLocationKey = this.graficoTortaLocationKey + 1;
                    this.graficoTortaLocationPropioKey = this.graficoTortaLocationPropioKey + 1;

                    this.isLoading = false;
                    this.getResultados = true;
                    console.log(this.charts[0]);

                });
        },
        list: function () {
            let contactData = {
                "status": "published",
                "first_name": this.first_name,
                "last_name": this.last_name,
                "whatsapp": this.whatsapp,
                "owner": 2
            }

            this.$store.dispatch('wookieContact/list', contactData.owner)
                .then((res) => {
                    this.contactList = res;
                    var selectedItems = this.selectedItems;
                    setTimeout(function () {
                        this.dataTable = $('#datatable').DataTable({
                            select: {
                                style: 'multi'
                            },
                            autoFill: {
                                update: true
                            }, language: {
                                url: '//cdn.datatables.net/plug-ins/1.11.5/i18n/es-ES.json'
                            },
                            buttons: [
                                'selectAll',
                                'selectNone'
                            ]
                        });

                        dataTable.on('select', function (e, dt, type, indexes) {
                            var rowData = dataTable.rows(indexes).data().toArray();
                            var thisID = rowData[0]['DT_RowId'].split('-')[1];
                            selectedItems.push(thisID);
                            console.log(selectedItems);

                            //events.prepend( '<div><b>'+type+' selection</b> - '+JSON.stringify( rowData )+'</div>' );
                        })
                            .on('deselect', function (e, dt, type, indexes) {
                                var rowData = dataTable.rows(indexes).data().toArray();
                                var thisID = rowData[0]['DT_RowId'].split('-')[1];
                                const index = selectedItems.indexOf(thisID);
                                selectedItems.splice(index, 1);

                                console.log(selectedItems);
                                //events.prepend( '<div><b>'+type+' <i>de</i>selection</b> - '+JSON.stringify( rowData )+'</div>' );
                            });
                    }, 1500);
                })
                .catch(err => console.log(err))
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
span.select-info {
    display: none !important;
}

.warning-delete {
    display: inline-block;
    margin-bottom: 1.5rem !important;
    padding: 10px 20px;
    text-align: center;
    vertical-align: middle;
}

#list-agenda {
    background: yellow;
}

.a {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    height: 25px;
    width: 31px;

}

.b {
    position: relative;
    bottom: 5px;
    right: 1px;
}

td.cheaper {
    color: #535f6b;
    background: #ffbdbd;
}

td.greater {
    color: #535f6b;
    background: #bdffcd;
}

td.equal {
    background: #acdcff;
}
</style>

<style>
.vue-treeselect__control {
    height: 44px !important;
    background: #fafbfe !important;
    border: 1px solid #f5f5f6 !important;
}

.vue-treeselect__placeholder {
    color: #535f6b !important;
}
</style>